import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "../../components/layout"

const Doremify = () => {
  const data = useStaticQuery(graphql`
  {
    file( relativePath: {  eq: "doremify/README.md" } ) {
      childMarkdownRemark {
        html
      }
    }
  }
  `)

  const markdownHTML = data.file.childMarkdownRemark.html

  return (    
      <Layout pageTitle="Doremify"> 
        <div     
          className="markdownHTML-container"       
          dangerouslySetInnerHTML={{ __html: markdownHTML }}
        />
      </Layout>    
  )
}

export default Doremify